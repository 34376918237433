<template>
	<v-container>
		<Loader v-if="fetchingData"></Loader>
		<div v-else class="unlock-parcours-page">
			<div class="text-center">
				<Content :parcours="parcours" :hideRuleTagsBlock="this.formationProgress.formation.for_session" />
			</div>
			<div class="actions text-center">
				<v-btn dark @click="handleRedirect">C'est parti !</v-btn>
			</div>
		</div>
	</v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Loader from '@/components/other/Loader.vue';
import Content from '@/components/dashboard/profile/parcoursModal/Content.vue';
import { ParcoursItemStatus } from '@/constants/parcours.js';

export default {
	name: 'ChooseParcousToUnlock',
	components: { Loader, Content },
	data() {
		return { parcours: null, fetchingData: true };
	},
	computed: {
		...mapGetters('profile', { formationProgress: 'formationProgress' }),
	},
	async created() {
		if (this.formationProgress == null)
			await this.$store.dispatch('profile/formationProgress', { idFormation: this.$route.params.idFormation });
		this.chooseLockParcousItemToDisplay(this.formationProgress.config.parcours_list);
		this.$confetti.start({ particlesPerFrame: 0.04 });
		this.fetchingData = false;
	},
	beforeDestroy() {
		this.$confetti.stop();
	},
	methods: {
		async chooseLockParcousItemToDisplay(parcoursListItems) {
			const parcoursItemToDisplay = parcoursListItems.find((item) => item.status === ParcoursItemStatus.open);
			this.parcours = parcoursItemToDisplay.parcours_id;
		},
		redirectForOrthograph() {
			const firstCategory = this.parcours.categories.find((cat) => 1 === cat.position_list);
			this.$router.push({
				name: 'Category Detail',
				params: { idParcours: this.parcours._id, idCategory: firstCategory._id },
			});
		},
		redirectForRedaction() {
			this.$router.push({
				name: 'Redaction Dashboard Index',
				params: { formationId: this.$route.params.idFormation },
			});
		},
		handleRedirect() {
			if (this.formationProgress.formation.for_session) this.redirectForRedaction();
			else this.redirectForOrthograph();
		},
	},
};
</script>

<style lang="scss" scoped>
.unlock-parcours-page {
	margin: 0 auto;
	width: 100%;
	@media screen and (min-width: 960px) {
		width: 750px;
	}
}
</style>
